window._ = require('lodash');

import Swal from 'sweetalert2'
import PerfectScrollbar from 'perfect-scrollbar';

try {
    require('bootstrap')
} catch (e) { }

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.Swal = Swal
window.PerfectScrollbar = PerfectScrollbar
